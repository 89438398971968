/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { StaffDealsReferenceManyToMany } from '../Security/Acl/StaffDealsReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IStagesDealsAttributes extends IModelAttributes {
	stagesId: string;
	dealsId: string;

	stages: Models.StageEntity | Models.IStageEntityAttributes;
	deals: Models.DealEntity | Models.IDealEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('StagesDeals')
export default class StagesDeals extends Model implements IStagesDealsAttributes {
	public static acls: IAcl[] = [
		new StaffDealsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public stagesId: string;

	@observable
	@attribute()
	public dealsId: string;

	@observable
	@attribute({isReference: true})
	public stages: Models.StageEntity;

	@observable
	@attribute({isReference: true})
	public deals: Models.DealEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IStagesDealsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.stagesId) {
				this.stagesId = attributes.stagesId;
			}
			if (attributes.dealsId) {
				this.dealsId = attributes.dealsId;
			}

			if (attributes.stages) {
				if (attributes.stages instanceof Models.StageEntity) {
					this.stages = attributes.stages;
					this.stagesId = attributes.stages.id;
				} else {
					this.stages = new Models.StageEntity(attributes.stages);
					this.stagesId = this.stages.id;
				}
			} else if (attributes.stagesId !== undefined) {
				this.stagesId = attributes.stagesId;
			}

			if (attributes.deals) {
				if (attributes.deals instanceof Models.DealEntity) {
					this.deals = attributes.deals;
					this.dealsId = attributes.deals.id;
				} else {
					this.deals = new Models.DealEntity(attributes.deals);
					this.dealsId = this.deals.id;
				}
			} else if (attributes.dealsId !== undefined) {
				this.dealsId = attributes.dealsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
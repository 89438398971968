/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { StaffActivityEntity } from 'Models/Security/Acl/StaffActivityEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IActivityEntityAttributes extends IModelAttributes {
	description: string;
	metadata: string;
	fingerprintId: string;
	activityType: Enums.activityType;

	contactId?: string;
	contact?: Models.ContactEntity | Models.IContactEntityAttributes;
	dealId?: string;
	deal?: Models.DealEntity | Models.IDealEntityAttributes;
	organisationId?: string;
	organisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ActivityEntity', 'Activity')
// % protected region % [Customise your entity metadata here] end
export default class ActivityEntity extends Model implements IActivityEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new StaffActivityEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Description'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Description',
		displayType: 'textarea',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Metadata'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Metadata',
		displayType: 'textarea',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public metadata: string;
	// % protected region % [Modify props to the crud options here for attribute 'Metadata'] end

	// % protected region % [Modify props to the crud options here for attribute 'Fingerprint Id'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Fingerprint Id',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public fingerprintId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Fingerprint Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Activity Type'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Activity Type',
		displayType: 'enum-combobox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.activityTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.activityTypeOptions),
		displayFunction: (attribute: Enums.activityType) => Enums.activityTypeOptions[attribute],
	})
	public activityType: Enums.activityType;
	// % protected region % [Modify props to the crud options here for attribute 'Activity Type'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Contact'] off begin
		name: 'Contact',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.ContactEntity,
		// % protected region % [Modify props to the crud options here for reference 'Contact'] end
	})
	public contactId?: string;
	@observable
	@attribute({isReference: true})
	public contact: Models.ContactEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Deal'] off begin
		name: 'Deal',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.DealEntity,
		// % protected region % [Modify props to the crud options here for reference 'Deal'] end
	})
	public dealId?: string;
	@observable
	@attribute({isReference: true})
	public deal: Models.DealEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] off begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId?: string;
	@observable
	@attribute({isReference: true})
	public organisation: Models.OrganisationEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IActivityEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IActivityEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.metadata !== undefined) {
				this.metadata = attributes.metadata;
			}
			if (attributes.fingerprintId !== undefined) {
				this.fingerprintId = attributes.fingerprintId;
			}
			if (attributes.activityType !== undefined) {
				this.activityType = attributes.activityType;
			}
			if (attributes.contactId !== undefined) {
				this.contactId = attributes.contactId;
			}
			if (attributes.contact !== undefined) {
				if (attributes.contact === null) {
					this.contact = attributes.contact;
				} else {
					if (attributes.contact instanceof Models.ContactEntity) {
						this.contact = attributes.contact;
						this.contactId = attributes.contact.id;
					} else {
						this.contact = new Models.ContactEntity(attributes.contact);
						this.contactId = this.contact.id;
					}
				}
			}
			if (attributes.dealId !== undefined) {
				this.dealId = attributes.dealId;
			}
			if (attributes.deal !== undefined) {
				if (attributes.deal === null) {
					this.deal = attributes.deal;
				} else {
					if (attributes.deal instanceof Models.DealEntity) {
						this.deal = attributes.deal;
						this.dealId = attributes.deal.id;
					} else {
						this.deal = new Models.DealEntity(attributes.deal);
						this.dealId = this.deal.id;
					}
				}
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else {
					if (attributes.organisation instanceof Models.OrganisationEntity) {
						this.organisation = attributes.organisation;
						this.organisationId = attributes.organisation.id;
					} else {
						this.organisation = new Models.OrganisationEntity(attributes.organisation);
						this.organisationId = this.organisation.id;
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		contact {
			${Models.ContactEntity.getAttributes().join('\n')}
		}
		deal {
			${Models.DealEntity.getAttributes().join('\n')}
		}
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ActivityEntity.prototype, 'created');
CRUD(modifiedAttr)(ActivityEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end

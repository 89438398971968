/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { StaffContactsReferenceManyToMany } from '../Security/Acl/StaffContactsReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IOrganisationsContactsAttributes extends IModelAttributes {
	organisationsId: string;
	contactsId: string;

	organisations: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	contacts: Models.ContactEntity | Models.IContactEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('OrganisationsContacts')
export default class OrganisationsContacts extends Model implements IOrganisationsContactsAttributes {
	public static acls: IAcl[] = [
		new StaffContactsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public organisationsId: string;

	@observable
	@attribute()
	public contactsId: string;

	@observable
	@attribute({isReference: true})
	public organisations: Models.OrganisationEntity;

	@observable
	@attribute({isReference: true})
	public contacts: Models.ContactEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IOrganisationsContactsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.organisationsId) {
				this.organisationsId = attributes.organisationsId;
			}
			if (attributes.contactsId) {
				this.contactsId = attributes.contactsId;
			}

			if (attributes.organisations) {
				if (attributes.organisations instanceof Models.OrganisationEntity) {
					this.organisations = attributes.organisations;
					this.organisationsId = attributes.organisations.id;
				} else {
					this.organisations = new Models.OrganisationEntity(attributes.organisations);
					this.organisationsId = this.organisations.id;
				}
			} else if (attributes.organisationsId !== undefined) {
				this.organisationsId = attributes.organisationsId;
			}

			if (attributes.contacts) {
				if (attributes.contacts instanceof Models.ContactEntity) {
					this.contacts = attributes.contacts;
					this.contactsId = attributes.contacts.id;
				} else {
					this.contacts = new Models.ContactEntity(attributes.contacts);
					this.contactsId = this.contacts.id;
				}
			} else if (attributes.contactsId !== undefined) {
				this.contactsId = attributes.contactsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
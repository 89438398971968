/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */

export type activityType =
	// % protected region % [Override activityType keys here] off begin
	'OTHER' |
		'LEGACY' |
		'TASK' |
		'EMAIL' |
		'CALL' |
		'MEETING' |
		'DEMO' |
		'FORM_SUBMISSION' |
		'LOGIN' |
		'GITLAB' |
		'PAYMENT' |
		'LESSON' |
		'COURSE' |
		'REFERRAL' |
		'LAUNCH' |
		'FREE_TRIAL' |
		'LINKEDIN' |
		'SOCIALS' |
		'FORUM';
	// % protected region % [Override activityType keys here] end

export const activityTypeOptions: { [key in activityType]: string } = {
	// % protected region % [Override activityType display fields here] off begin
	OTHER: 'Other',
	LEGACY: 'Legacy',
	TASK: 'Task',
	EMAIL: 'Email',
	CALL: 'Call',
	MEETING: 'Meeting',
	DEMO: 'Demo',
	FORM_SUBMISSION: 'Form Submission',
	LOGIN: 'Login',
	GITLAB: 'GitLab',
	PAYMENT: 'Payment',
	LESSON: 'Lesson',
	COURSE: 'Course',
	REFERRAL: 'Referral',
	LAUNCH: 'Launch',
	FREE_TRIAL: 'Free Trial',
	LINKEDIN: 'LinkedIn',
	SOCIALS: 'Socials',
	FORUM: 'Forum',
	// % protected region % [Override activityType display fields here] end
};

export type runStatus =
	// % protected region % [Override runStatus keys here] off begin
	'QUEUED' |
		'RUNNING' |
		'FAILED' |
		'SUCCESS' |
		'EXCEPTION';
	// % protected region % [Override runStatus keys here] end

export const runStatusOptions: { [key in runStatus]: string } = {
	// % protected region % [Override runStatus display fields here] off begin
	QUEUED: 'QUEUED',
	RUNNING: 'RUNNING',
	FAILED: 'FAILED',
	SUCCESS: 'SUCCESS',
	EXCEPTION: 'EXCEPTION',
	// % protected region % [Override runStatus display fields here] end
};

// % protected region % [Add any extra enums here] off begin
// % protected region % [Add any extra enums here] end

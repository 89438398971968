/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { StaffOrganisationEntity } from 'Models/Security/Acl/StaffOrganisationEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { TimelineModel } from 'Timelines/TimelineModel';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IOrganisationEntityAttributes extends IModelAttributes {
	name: string;
	metadata: string;

	activitiess: Array<Models.ActivityEntity | Models.IActivityEntityAttributes>;
	dealss: Array<Models.DealEntity | Models.IDealEntityAttributes>;
	loggedEvents: Array<Models.OrganisationTimelineEventsEntity | Models.IOrganisationTimelineEventsEntityAttributes>;
	contactss: Array<Models.OrganisationsContacts | Models.IOrganisationsContactsAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('OrganisationEntity', 'Organisation')
// % protected region % [Customise your entity metadata here] end
export default class OrganisationEntity extends Model implements IOrganisationEntityAttributes, TimelineModel  {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new StaffOrganisationEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@Validators.Unique()
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Metadata'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Metadata',
		displayType: 'textarea',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public metadata: string;
	// % protected region % [Modify props to the crud options here for attribute 'Metadata'] end

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Activities'] off begin
		name: "Activitiess",
		displayType: 'reference-multicombobox',
		order: 30,
		referenceTypeFunc: () => Models.ActivityEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'activitiess',
			oppositeEntity: () => Models.ActivityEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Activities'] end
	})
	public activitiess: Models.ActivityEntity[] = [];

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Deals'] off begin
		name: "Dealss",
		displayType: 'reference-multicombobox',
		order: 40,
		referenceTypeFunc: () => Models.DealEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'dealss',
			oppositeEntity: () => Models.DealEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Deals'] end
	})
	public dealss: Models.DealEntity[] = [];

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Logged Event'] off begin
		name: "Logged Events",
		displayType: 'hidden',
		order: 50,
		referenceTypeFunc: () => Models.OrganisationTimelineEventsEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'loggedEvents',
			oppositeEntity: () => Models.OrganisationTimelineEventsEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Logged Event'] end
	})
	public loggedEvents: Models.OrganisationTimelineEventsEntity[] = [];

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Contacts'] off begin
		name: 'Contacts',
		displayType: 'reference-multicombobox',
		order: 60,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.OrganisationsContacts,
		optionEqualFunc: makeJoinEqualsFunc('contactsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'organisationEntity',
			oppositeEntityName: 'contactEntity',
			relationName: 'organisations',
			relationOppositeName: 'contacts',
			entity: () => Models.OrganisationEntity,
			joinEntity: () => Models.OrganisationsContacts,
			oppositeEntity: () => Models.ContactEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Contacts'] end
	})
	public contactss: Models.OrganisationsContacts[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IOrganisationEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IOrganisationEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.metadata !== undefined) {
				this.metadata = attributes.metadata;
			}
			if (attributes.activitiess !== undefined && Array.isArray(attributes.activitiess)) {
				for (const model of attributes.activitiess) {
					if (model instanceof Models.ActivityEntity) {
						this.activitiess.push(model);
					} else {
						this.activitiess.push(new Models.ActivityEntity(model));
					}
				}
			}
			if (attributes.dealss !== undefined && Array.isArray(attributes.dealss)) {
				for (const model of attributes.dealss) {
					if (model instanceof Models.DealEntity) {
						this.dealss.push(model);
					} else {
						this.dealss.push(new Models.DealEntity(model));
					}
				}
			}
			if (attributes.loggedEvents !== undefined && Array.isArray(attributes.loggedEvents)) {
				for (const model of attributes.loggedEvents) {
					if (model instanceof Models.OrganisationTimelineEventsEntity) {
						this.loggedEvents.push(model);
					} else {
						this.loggedEvents.push(new Models.OrganisationTimelineEventsEntity(model));
					}
				}
			}
			if (attributes.contactss !== undefined && Array.isArray(attributes.contactss)) {
				for (const model of attributes.contactss) {
					if (model instanceof Models.OrganisationsContacts) {
						this.contactss.push(model);
					} else {
						this.contactss.push(new Models.OrganisationsContacts(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		contactss {
			${Models.OrganisationsContacts.getAttributes().join('\n')}
			contacts {
				${Models.ContactEntity.getAttributes().join('\n')}
			}
		}
		activitiess {
			${Models.ActivityEntity.getAttributes().join('\n')}
		}
		dealss {
			${Models.DealEntity.getAttributes().join('\n')}
		}
		loggedEvents {
			${Models.OrganisationTimelineEventsEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			contactss: {},
			activitiess: {},
			dealss: {},
			loggedEvents: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'activitiess',
							'dealss',
							'loggedEvents',
							'contactss',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	/**
	 * Gets the timeline event entity type for this form.
	 */
	public getTimelineEventEntity = () => {
		// % protected region % [Modify the getTimelineEventEntity here] off begin
		return Models.OrganisationTimelineEventsEntity;
		// % protected region % [Modify the getTimelineEventEntity here] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(OrganisationEntity.prototype, 'created');
CRUD(modifiedAttr)(OrganisationEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end

/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { StaffStageEntity } from 'Models/Security/Acl/StaffStageEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IStageEntityAttributes extends IModelAttributes {
	name: string;
	description: string;
	sortOrder: number;

	pipelineId?: string;
	pipeline?: Models.PipelineEntity | Models.IPipelineEntityAttributes;
	dealss: Array<Models.StagesDeals | Models.IStagesDealsAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('StageEntity', 'Stage')
// % protected region % [Customise your entity metadata here] end
export default class StageEntity extends Model implements IStageEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new StaffStageEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Description',
		displayType: 'textarea',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Sort Order'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Sort Order',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public sortOrder: number;
	// % protected region % [Modify props to the crud options here for attribute 'Sort Order'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Pipeline'] off begin
		name: 'Pipeline',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.PipelineEntity,
		// % protected region % [Modify props to the crud options here for reference 'Pipeline'] end
	})
	public pipelineId?: string;
	@observable
	@attribute({isReference: true})
	public pipeline: Models.PipelineEntity;

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Deals'] off begin
		name: 'Deals',
		displayType: 'reference-multicombobox',
		order: 50,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.StagesDeals,
		optionEqualFunc: makeJoinEqualsFunc('dealsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'stageEntity',
			oppositeEntityName: 'dealEntity',
			relationName: 'stages',
			relationOppositeName: 'deals',
			entity: () => Models.StageEntity,
			joinEntity: () => Models.StagesDeals,
			oppositeEntity: () => Models.DealEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Deals'] end
	})
	public dealss: Models.StagesDeals[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IStageEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IStageEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.sortOrder !== undefined) {
				this.sortOrder = attributes.sortOrder;
			}
			if (attributes.pipelineId !== undefined) {
				this.pipelineId = attributes.pipelineId;
			}
			if (attributes.pipeline !== undefined) {
				if (attributes.pipeline === null) {
					this.pipeline = attributes.pipeline;
				} else {
					if (attributes.pipeline instanceof Models.PipelineEntity) {
						this.pipeline = attributes.pipeline;
						this.pipelineId = attributes.pipeline.id;
					} else {
						this.pipeline = new Models.PipelineEntity(attributes.pipeline);
						this.pipelineId = this.pipeline.id;
					}
				}
			}
			if (attributes.dealss !== undefined && Array.isArray(attributes.dealss)) {
				for (const model of attributes.dealss) {
					if (model instanceof Models.StagesDeals) {
						this.dealss.push(model);
					} else {
						this.dealss.push(new Models.StagesDeals(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		dealss {
			${Models.StagesDeals.getAttributes().join('\n')}
			deals {
				${Models.DealEntity.getAttributes().join('\n')}
			}
		}
		pipeline {
			${Models.PipelineEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			dealss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'dealss',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(StageEntity.prototype, 'created');
CRUD(modifiedAttr)(StageEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
